import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { CardLinkProps } from "./Card"
import { Image } from "../Image"
import { Link } from "../Link"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"

const CardLink: React.FC<CardLinkProps> = ({
  cardType,
  title,
  description,
  link,
  backgroundImage,
  icon,
  downloadFile,
}) => {
  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language, description, text, title])

  const cardLinkUrl =
    cardType === "link" ? link?.url : downloadFile?.localFile?.publicURL

  return (
    <div css={[tw` max-w-[23rem]`]}>
      <div
        css={tw`relative md:px-0 bg-[#202f45] hover:bg-[#007f7b] focus:bg-[#007f7b]`}
        tabIndex={cardLinkUrl ? -1 : 0}
      >
        {cardLinkUrl ? (
          <Link
            to={cardLinkUrl}
            css={[tw`hover:bg-[#007f7b] focus:bg-[#007f7b]`]}
          >
            <Image
              src={backgroundImage?.localFile?.childImageSharp?.gatsbyImageData}
              publicURL={backgroundImage?.localFile?.publicURL}
              role="presentation"
              css={[tw`opacity-25 grayscale max-w-[23rem] h-[13rem]`]}
              alt=""
            />
            <div
              css={[
                tw`top-0 left-0 absolute w-full h-full flex flex-col justify-center items-center gap-2`,
              ]}
            >
              <Image aria-hidden src={icon?.localFile?.publicURL} alt="" />
              <p css={[tw`text-white text-3xl px-2 text-center`]}>
                {text?.title}
              </p>
            </div>
          </Link>
        ) : (
          <>
            <Image
              src={backgroundImage?.localFile?.childImageSharp?.gatsbyImageData}
              publicURL={backgroundImage?.localFile?.publicURL}
              role="presentation"
              css={[tw`opacity-25 grayscale max-w-[23rem] h-[13rem]`]}
              alt=""
            />
            <div
              css={[
                tw`top-0 left-0 absolute w-full h-full flex flex-col justify-center items-center gap-2`,
              ]}
            >
              <Image aria-hidden src={icon?.localFile?.publicURL} alt="" />
              <p css={[tw`text-white text-3xl px-2 text-center`]}>
                {text?.title}
              </p>
            </div>
          </>
        )}
      </div>
      {description && (
        <p
          dangerouslySetInnerHTML={{ __html: text?.description }}
          css={[tw`mt-5 md:mt-4 md:mb-2 lg:mt-5`]}
        />
      )}
    </div>
  )
}

export default CardLink
